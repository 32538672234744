<template>
  <!--<div class="d-flex">
    <v-text-field
      v-model="link"
      :rules="[rules.required]"
      label="Odkaz na web konference"
      :class="{ 'mb-2': true }"
      outlined
      dense
      hide-details="auto"
      :error-messages="linkError"
    >
      <template #append>
        <v-icon color="primary" @click="openLink">
          mdi-open-in-new
        </v-icon>
      </template>
    </v-text-field>

    <v-menu left offset-x>
      <template #activator="{ on }">
        <v-btn small class="ml-2" color="primary" :loading="reporting" :disabled="disabledButtons" v-on="on">
          <v-icon color="white">
            mdi-alert
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="$emit('not-conference')">
          <v-list-item-title class="primary--text">Toto není web konference</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('aggregator')">
          <v-list-item-title class="primary--text">Je to agregátor</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('already-happened')">
          <v-list-item-title class="primary--text">Událost již proběhla</v-list-item-title>
        </v-list-item>

        <v-list-item @click="showReportWindow">
          <v-list-item-title class="primary--text">Jiný problém &hellip;</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>-->

  <div class="d-flex flex-column g-2">
    <v-text-field
      v-model="organizer"
      :rules="[rules.required]"
      label="Pořadatel konference*"
      outlined
      dense
      hide-details="auto"
      :error-messages="organizerError"
    />
    <v-text-field
      v-model="title"
      :rules="[rules.required]"
      label="Název konference*"
      outlined
      dense
      hide-details="auto"
      :error-messages="titleError"
    />

    <div class="d-flex g-2">
      <v-text-field
        ref="fromDate"
        v-model="frontendFromDate"
        :rules="[rules.required, rules.date, rules.futureDateRule]"
        label="Datum od*"
        placeholder="DD/MM/RRRR"
        outlined
        dense
        hide-details="auto"
        :error-messages="fromDateError"
        @blur="setFromDateFromFrontend()"
      />
      <v-text-field
        ref="toDate"
        v-model="frontendToDate"
        :rules="[rules.required, rules.date, rules.futureDateRule]"
        label="Datum do*"
        placeholder="DD/MM/RRRR"
        outlined
        dense
        hide-details="auto"
        :error-messages="toDateError"
        @blur="setToDateFromFrontend()"
      />
    </div>

    <div class="d-flex justify-end">
      <v-checkbox
        ref="virtualConferece"
        v-model="virtualConference"
        dense
        label="Virtuální konference"
        hide-details="auto"
        class="ma-0 pa-0 text-right"
        right
      />
    </div>

    <v-text-field
      v-show="!virtualConference"
      v-model="location"
      label="Místo konání"
      outlined
      dense
      hide-details="auto"
      :error-messages="locationError"
    />

    <ReportModal ref="reportModal" @send="sendReport" />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import rules from '@/helpers/rules';
import formatFrontendDate from '@/helpers/format_frontend_date';
import parseFrontendDate from '@/helpers/parse_frontend_date';

import ReportModal from './ReportModal.vue';

export default {
  components: {
    ReportModal,
  },

  props: {
    notConferenceAtBottom: Boolean,
    reporting: Boolean,
    disabledButtons: Boolean,
  },

  data() {
    return {
      rules,
      frontendFromDate: '',
      frontendToDate: '',
    };
  },

  computed: {
    ...mapFields({
      link: 'job.details.link',
      organizer: 'job.details.organizer',
      title: 'job.details.title',
      fromDate: 'job.details.fromDate',
      toDate: 'job.details.toDate',
      virtualConference: 'job.details.virtualConference',
      location: 'job.details.location',

      linkError: 'job.detailsErrors.link',
      organizerError: 'job.detailsErrors.organizer',
      titleError: 'job.detailsErrors.title',
      fromDateError: 'job.detailsErrors.fromDate',
      toDateError: 'job.detailsErrors.toDate',
      locationError: 'job.detailsErrors.location',
    }),
  },

  watch: {
    fromDate(newValue) {
      this.frontendFromDate = formatFrontendDate(newValue);
    },

    toDate(newValue) {
      this.frontendToDate = formatFrontendDate(newValue);
    },
  },

  mounted() {
    this.frontendFromDate = formatFrontendDate(this.fromDate);
    this.frontendToDate = formatFrontendDate(this.toDate);
  },

  methods: {
    openLink() {
      window.open(this.link, '_blank');
    },

    setFromDateFromFrontend() {
      this.fromDate = parseFrontendDate(this.frontendFromDate);
    },

    setToDateFromFrontend() {
      this.toDate = parseFrontendDate(this.frontendToDate);
    },

    showReportWindow() {
      this.$refs.reportModal.show();
    },

    hideReportWindow() {
      this.$refs.reportModal.hide();
    },

    sendReport(reason) {
      this.$emit('report', reason);
    },
  },
};
</script>

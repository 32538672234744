<template>
  <JobDetails ref="job" job-type="details" />
</template>

<script>
import JobDetails from '@/components/JobDetails.vue';

export default {
  components: {
    JobDetails,
  },
};
</script>

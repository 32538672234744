import Vue from 'vue';

import BrowserAddonController from '@/browser_addon_controller';

import App from './App.vue';

import vuetify from './vuetify';
import router from './router';
import store from './store';

BrowserAddonController.instance().on('selectionToolAction', (params) => {
  const selectionToolType = params.type;

  if (selectionToolType === 'details') {
    store.dispatch('setJobField', {
      field: params.field,
      value: params.value.trim(),
    });
  } else if (selectionToolType === 'contacts') {
    store.dispatch('setJobContactField', {
      field: params.field,
      value: params.value.trim(),
    });

    store.dispatch('setJobContactField', {
      field: 'link',
      value: params.link.trim(),
    });
  }
});

BrowserAddonController.instance().on('reset', () => {
  store.dispatch('reset');
});

router.push(store.state.route || '/');

store.watch(
  (state) => state.route,
  (newValue) => {
    if (router.currentRoute.path !== newValue) {
      router.push(newValue);
    }
  },
);

// eslint-disable-next-line no-unused-vars
const vue = new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  render: (h) => h(App),
});

<template>
  <div class="contactTabs">
    <v-toolbar flat height="28" class="contactTabButtons mb-2">
      <v-toolbar-items>
        <div
          v-for="(contact, index) in contacts"
          :key="index"
          :class="{ contactTabButtons__button: true, active: tab === index }"
          style="position: relative;"
        >
          <v-icon size="28" class="contactTabIcon" @click="openContact(index)">mdi-account</v-icon>
          <v-icon
            v-if="contactCount > 1"
            size="12"
            style="position: absolute; top: -1px; right: -1px;"
            @click="removeContact(index)"
            >mdi-close</v-icon
          >
        </div>

        <v-spacer />

        <v-btn v-if="contactCount < maxContactCount" outlined class="px-2" @click="addEmptyContact">
          <v-icon size="28">mdi-account-plus</v-icon>

          <div class="text-body-2 font-weight-bold ml-1">
            <template v-if="jobNextContactReward > 0">
              (+ {{ jobNextContactReward / 100 }} K<span class="text-lowercase">č</span>)
            </template>
          </div>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-tabs v-model="tab" background-color="white" left class="mb-1">
      <v-tab-item v-for="(contact, index) in contacts" :key="index" :transition="false">
        <ConferenceContact
          ref="contactTabs"
          :contact="contact"
          :contact-errors="contactsErrors[index]"
          :index="index"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapMultiRowFields, mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';

import ConferenceContact from './ConferenceContact.vue';

export default {
  components: {
    ConferenceContact,
  },

  data() {
    return {
      maxContactCount: 5,
    };
  },

  computed: {
    ...mapMultiRowFields({ contacts: 'job.contacts' }),
    ...mapMultiRowFields({ contactsErrors: 'job.contactsErrors' }),

    ...mapFields({ tab: 'jobContactsTab' }),
    ...mapGetters({ jobNextContactReward: 'jobNextContactReward' }),

    contactCount() {
      return this.contacts.length;
    },
  },

  methods: {
    removeContact(index) {
      this.$store.dispatch('removeContact', index);
    },

    openContact(index) {
      this.tab = index;
    },

    addEmptyContact() {
      if (this.contactCount >= this.maxContactCount) {
        return;
      }

      let firstInvalidContact = -1;
      for (let i = 0; i < this.$refs.contactTabs.length; ++i) {
        const contactTab = this.$refs.contactTabs[i];
        if (!contactTab.validate()) {
          firstInvalidContact = i;
        }
      }

      if (firstInvalidContact < 0) {
        this.$store.dispatch('addEmptyContact');
        setTimeout(() => {
          this.tab = this.contactCount - 1;
        }, 0);
      } else {
        this.tab = firstInvalidContact;
      }
    },
  },
};
</script>

<style lang="postcss">
.v-tabs-bar {
  height: 35px !important;
}

.v-tab {
  width: 40px !important;
  min-width: initial !important;
  max-width: initial !important;
  padding: 0 !important;
  text-transform: none !important;
}

.contactTabs {
  .v-tabs-bar[role='tablist'] {
    display: none;
  }

  .v-window {
    overflow: visible;
  }
}

.contactTabButtons {
  .v-toolbar__items {
    width: 100%;

    .v-btn {
      border-radius: 4px;
    }
  }

  .v-toolbar__content {
    padding: 0;
  }

  &__button {
    width: 36px;
    text-align: center;

    &.active {
      .v-icon {
        color: rgba(0, 0, 0, 0.87);
      }

      .contactTabIcon {
        border-bottom: 2px solid #8351f9;
      }
    }
  }
}
</style>

<template>
  <div ref="chart" />
</template>

<script>
import * as d3 from 'd3';
import colors from 'vuetify/lib/util/colors';

export default {
  props: {
    hideLabel: Boolean,
    width: { type: Number, default: 300 },
    height: { type: Number, default: 300 },
    data: { type: Object, default: () => ({ A: 5, B: 10 }) },
  },

  data() {
    return {
      id: '',
    };
  },

  mounted() {
    const pieChartRandomId = Math.random().toString(36).substring(2);
    this.id = `pie-chart-${pieChartRandomId}`;

    const options = {
      events: [],
      animation: false,
      plugins: {},
    };

    if (this.hideLabel) {
      options.legend = {
        display: false,
      };
      options.tooltips = {
        enabled: false,
      };
    } else {
      options.plugins.labels = {
        render: 'label',
      };
    }

    this.$refs.chart.setAttribute('id', this.id);
    this.render();
  },

  methods: {
    render() {
      const radius = Math.min(this.width, this.height) / 2;
      const svg = d3
        .select(`#${this.id}`)
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height)
        .append('g')
        .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`);

      const pie = d3
        .pie()
        .value((d) => d[1].value)
        .sort(null);
      const pieData = pie(Object.entries(this.data));

      const arcGenerator = d3.arc().innerRadius(0).outerRadius(radius);

      const colorPalette = this.colorPalette();

      svg
        .selectAll('slices')
        .data(pieData)
        .enter()
        .append('path')
        .attr('d', arcGenerator)
        .attr('fill', (d) => colorPalette[d.index].bg);

      if (this.hideLabel) {
        const d = pieData[0];
        const pct = Math.round(((d.endAngle - d.startAngle) / (Math.PI * 2)) * 100);
        const text = `${pct}%`;

        svg
          .append('text')
          .text(text)
          .style('text-anchor', 'middle')
          .style('dominant-baseline', 'central')
          .style('font-size', 16)
          .style('font-weight', 'bold')
          .style('fill', 'white');
      } else {
        const textThreshold = 15;

        svg
          .selectAll('slices')
          .data(pieData)
          .enter()
          .append('text')
          .attr('dy', '-10')
          .text((d) => {
            const pct = Math.round(((d.endAngle - d.startAngle) / (Math.PI * 2)) * 100);
            return pct < textThreshold ? '' : `${pct}%`;
          })
          .attr('transform', (d) => `translate(${arcGenerator.centroid(d)})`)
          .style('text-anchor', 'middle')
          .style('font-size', 24)
          .style('font-weight', 'bold')
          .style('fill', (d) => colorPalette[d.index].primary);

        svg
          .selectAll('slices')
          .data(pieData)
          .enter()
          .append('text')
          .attr('dy', '10')
          .text((d) => {
            const pct = Math.round(((d.endAngle - d.startAngle) / (Math.PI * 2)) * 100);
            return pct < textThreshold ? '' : d.data.key;
          })
          .attr('transform', (d) => `translate(${arcGenerator.centroid(d)})`)
          .style('text-anchor', 'middle')
          .style('text-transform', 'uppercase')
          .style('font-size', 16)
          .style('font-weight', 'bold')
          .style('fill', (d) => colorPalette[d.index].secondary);
      }
    },

    colorPalette() {
      if (this.hideLabel) {
        return [
          {
            bg: '#4A2A95',
          },
          {
            bg: '#8351F9',
          },
        ];
      }

      return [
        {
          bg: '#8351F9',
          primary: '#FFFFFF',
          secondary: '#FFFFFF80',
        },
        {
          bg: colors.grey.lighten3,
          primary: colors.grey.darken2,
          secondary: colors.grey.base,
        },
      ];
    },
  },
};
</script>

<template>
  <Modal ref="modal">
    <v-card color="grey lighten-3" width="320px">
      <v-card-text class="white--text text-uppercase text-center">
        <v-textarea
          v-model="reason"
          placeholder="Popište, co je za problém a my se na to podíváme."
          :class="{ 'mb-4': true }"
          outlined
          dense
          hide-details
          light
        />

        <v-btn color="primary" :loading="loading" @click="report">
          Nahlásit
        </v-btn>
      </v-card-text>
    </v-card>
  </Modal>
</template>

<script>
import rules from '@/helpers/rules';

import Modal from './Modal.vue';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      loading: false,
      reason: '',
      rules,
    };
  },

  computed: {},

  methods: {
    show() {
      this.loading = false;
      this.$refs.modal.show();
    },

    hide() {
      this.$refs.modal.hide();
    },

    report() {
      this.loading = true;
      this.$emit('report', this.reason);
      this.hide();
    },
  },
};
</script>

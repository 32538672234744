<template>
  <Job ref="job" v-slot="props" job-type="contacts" :reward="contactsReward" send-text="Přidat údaje do databáze">
    <div class="px-6 py-4 d-flex flex-column g-2">
      <ConferenceDetailsBox
        :fill-in-contacts="!props.anyActionInProgress"
        :skipping="props.skipping"
        :skipped="props.skipped"
        :verifying="props.sending"
        :verified="props.sent"
      />

      <div class="text-body-2">
        <div class="font-weight-bold">Vyplňte kontakty na pořadatele</div>
        <div>{{ title }}</div>
      </div>

      <ConferenceContacts />
    </div>
  </Job>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import Job from '@/components/Job.vue';
import ConferenceDetailsBox from '@/components/ConferenceDetailsBox.vue';
import ConferenceContacts from '@/components/ConferenceContacts.vue';

export default {
  components: {
    Job,
    ConferenceDetailsBox,
    ConferenceContacts,
  },

  computed: {
    ...mapFields({
      title: 'job.details.title',
    }),

    ...mapGetters({
      contactsReward: 'jobContactsReward',
    }),
  },
};
</script>

export default {
  stateVersion: 14,

  enabled: false,
  globalError: null,

  route: '/',

  apiToken: null,

  person: {
    email: '',

    name: '',
    hello: '',
    initials: '',

    level: 3,

    jobsDoneThisLevel: 15,
    jobsToNextLevel: 65,

    payout: 0,
    jobsVerifying: 0,
    jobsVerifyingEarned: 0,
  },

  jobPool: {
    publishedAt: '15. 10. 2019',
    jobsDone: 23,
    jobsTotal: 100,
    earnedTotal: 346110,
    workingPeople: 5,
  },

  session: {
    working: false,
    startedAt: null,
    finishedJobs: {},
  },

  job: {
    startedAt: null,

    id: 0,
    reportReward: 0,
    reward: 0,
    contactRewards: [],
    details: null,
    contacts: null,

    genericContactPenaltyPct: 0,
    genericContactEmailPatterns: [],
  },

  jobContactsTab: 0,
};

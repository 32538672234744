import BrowserAddonController from './index';

export default function () {
  const stateId = crypto.randomUUID();

  function replaceState(store, state) {
    if (!state) {
      return;
    }

    if (store.state.stateVersion !== state.stateVersion) {
      return;
    }

    store.replaceState(state);
  }

  return (store) => {
    BrowserAddonController.instance()
      .fetchState()
      .then(({ state }) => {
        if (state) {
          replaceState(store, state);
        } else {
          store.dispatch('reset');
        }
      });

    BrowserAddonController.instance().on('stateChanged', ({ stateId: newStateId, state: newState }) => {
      if (newStateId !== stateId) {
        if (newState) {
          replaceState(store, newState);
        } else {
          store.dispatch('reset');
        }
      }
    });

    store.subscribe((mutation, state) => {
      BrowserAddonController.instance().pushState({ stateId, state });
    });
  };
}

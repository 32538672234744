<template>
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-card color="white">
      <v-card-text class="py-5 text--primary">
        <div class="title pb-5">Automatická detekce<br />podezřelého chování v Kaminu</div>

        <p>
          U vašeho účtu jsme detekovali nesrovnalosti v zadaných datech. Zadávání nepravdivých údajů může být
          vyhodnoceno jako pokus o podvod a může vést k ukončení spolupráce a k vymáhání podvodně získaných peněz.
        </p>
        <p>
          Podezřelá data jsou kontrolována našimi zaměstnanci. Zadávejte vždy pravdivá data.
        </p>
        <p>
          Pokud myslíte, že se tato automatická zpráva zobrazila omylem, tak nás kontaktujte na
          <a href="mailto:kamino@slideslive.com">kamino@slideslive.com</a>.
        </p>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn text @click="$emit('close')">Pokračovat v práci</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    show() {
      this.dialog = true;
    },

    hide() {
      this.dialog = false;
    },
  },
};
</script>

<template>
  <div class="pa-4 d-flex flex-column rounded grey lighten-4">
    <div class="text-body-2">
      <div class="font-weight-bold">{{ organizer }}</div>
      <div>{{ title }}</div>
      <div v-if="fromDate !== toDate">{{ frontendFromDate }} &ndash; {{ frontendToDate }}</div>
      <div v-if="fromDate === toDate">{{ frontendFromDate }}</div>
      <div>{{ location }}</div>
    </div>

    <div v-if="skipping && !skipped" class="text-center font-weight-bold primary--text">
      <div>Přeskakování&hellip;</div>
    </div>

    <div v-if="skipped" class="text-center font-weight-bold primary--text">
      <div>
        <v-icon size="20" class="primary--text" style="vertical-align: middle; margin-top: -2px"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        Přeskočeno.
      </div>
    </div>

    <div v-if="verifying && !verified && !contactsAlreadyExists" class="text-center font-weight-bold primary--text">
      <div>Ověřování&hellip;</div>
    </div>

    <div v-if="verified" class="text-center font-weight-bold primary--text">
      <div>
        <v-icon size="20" class="primary--text" style="vertical-align: middle; margin-top: -2px"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        Uloženo.
      </div>
    </div>

    <div v-if="contactsAlreadyExists" class="text-center font-weight-bold primary--text">
      <div>
        <v-icon size="20" class="primary--text" style="vertical-align: middle; margin-top: -2px"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        Kontakty již máme.
      </div>
    </div>

    <div v-if="fillInContacts && !skipping && !verifying" class="text-center font-weight-bold green--text">
      <div>Žádné kontakty nebyly nalezeny.</div>
      <div>Vyplňte je.</div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import formatFrontendDate from '@/helpers/format_frontend_date';

export default {
  components: {},

  props: {
    contactsAlreadyExists: { type: Boolean, default: false },
    fillInContacts: { type: Boolean, default: false },
    skipping: Boolean,
    skipped: Boolean,
    verifying: { type: Boolean, default: false },
    verified: { type: Boolean, default: false },
  },

  computed: {
    ...mapFields({
      link: 'job.details.link',
      organizer: 'job.details.organizer',
      title: 'job.details.title',
      fromDate: 'job.details.fromDate',
      toDate: 'job.details.toDate',
      location: 'job.details.location',
    }),

    frontendFromDate() {
      return formatFrontendDate(this.fromDate);
    },

    frontendToDate() {
      return formatFrontendDate(this.toDate);
    },
  },

  methods: {},
};
</script>

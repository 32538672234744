<template>
  <v-overlay opacity="0.8" z-index="99" :value="visible" color="white">
    <slot />
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },

  methods: {
    show() {
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },
  },
};
</script>

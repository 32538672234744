import BrowserAddonController from '@/browser_addon_controller';

const ApiRootUrl = 'https://kamino.slideslive.com/api/v1';

function apiUrl(path, query) {
  let url = `${ApiRootUrl}/${path}`;

  if (query) {
    url += '?';
    url += Object.keys(query)
      .reduce((a, k) => {
        a.push(`${k}=${encodeURIComponent(query[k])}`);
        return a;
      }, [])
      .join('&');
  }

  return url;
}

async function apiGet(path, query) {
  if (!query) {
    query = {};
  }

  const addon = await BrowserAddonController.instance().version();
  query = { ...query, addon_version: addon.version };

  const response = await fetch(apiUrl(path, query), {
    headers: {
      Accept: 'application/json',
      'X-SlidesLive-Kamino-Addon-Name': addon.name,
      'X-SlidesLive-Kamino-Addon-Version': addon.version,
    },
  });
  const json = JSON.parse(await response.text());

  return json;
}

async function apiPost(path, data) {
  const addon = await BrowserAddonController.instance().version();

  const response = await fetch(apiUrl(path), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ ...data, addon_version: addon.version }),
  });
  const json = JSON.parse(await response.text());

  return json;
}

function setPerson(context, person) {
  context.commit('setPerson', person);
}

function setPool(context, pool) {
  context.commit('setJobPool', pool);
}

export default {
  turnOn(context) {
    context.commit('setEnabled', true);
  },

  turnOff(context) {
    context.commit('setEnabled', false);
  },

  signIn(context, { email, password, addonVersion, frontendVersion }) {
    return new Promise((resolve, reject) => {
      apiPost('sign_in', { email, password, addon_version: addonVersion, frontend_version: frontendVersion })
        .then((response) => {
          if (!response.success) {
            const error = new Error('SignInError');
            error.response = response;
            throw error;
          }

          context.commit('setApiToken', { apiToken: response.data.token });

          return Promise.all([
            apiGet('people/me', { api_token: context.state.apiToken }),
            apiGet('job_pools/current', { api_token: context.state.apiToken }),
          ]);
        })
        .then(([personResponse, jobPoolResponse]) => {
          if (!personResponse.success) {
            const error = new Error('PersonError');
            error.response = personResponse;
            throw error;
          }

          if (!jobPoolResponse.success) {
            const error = new Error('JobPoolError');
            error.response = jobPoolResponse;
            throw error;
          }

          setPerson(context, personResponse.data);
          setPool(context, jobPoolResponse.data);

          resolve();
        })
        .catch((error) => {
          context.commit('resetApiToken');

          console.warn(error);
          reject(error);
        });
    });
  },

  signOut(context) {
    context.commit('resetApiToken');
  },

  addSessionJobDone(context, { jobId, baseReward, contactsReward, bonus, level }) {
    context.commit('addSessionJobDone', {
      jobId,
      baseReward,
      contactsReward,
      bonus,
      level,
    });
  },

  updatePerson(context) {
    return new Promise((resolve, reject) => {
      apiGet('people/me', { api_token: context.state.apiToken })
        .then((response) => {
          if (!response.success) {
            throw new Error();
          }

          setPerson(context, response.data);
          resolve();
        })
        .catch((error) => {
          console.warn(error);
          reject();
        });
    });
  },

  updateJobPool(context) {
    return new Promise((resolve, reject) => {
      apiGet('job_pools/current', { api_token: context.state.apiToken })
        .then((response) => {
          if (!response.success) {
            throw new Error();
          }

          setPool(context, response.data);
          resolve();
        })
        .catch((error) => {
          console.warn(error);
          reject();
        });
    });
  },

  nextJob(context) {
    return new Promise((resolve, reject) => {
      const poolId = context.state.jobPool.id;

      apiGet(`job_pools/${poolId}/next_job`, { api_token: context.state.apiToken })
        .then((response) => {
          const jobId = response.data.job_id;

          if (typeof jobId === 'undefined') {
            return null;
          }

          return apiGet(`jobs/${jobId}/edit`, { api_token: context.state.apiToken });
        })
        .then((result) => {
          if (result) {
            const job = result.data;
            context.commit('setJob', job);

            let jobPath;
            if (job.type === 'contacts') {
              jobPath = '/jobs/details';
            } else {
              jobPath = '/jobs/details';
            }

            resolve({ hasJob: true, path: jobPath });

            BrowserAddonController.instance().navigate(job.details.link);
          } else {
            resolve({ hasJob: false });
          }
        })
        .catch((error) => {
          console.warn(error);
          reject();
        });
    });
  },

  startWorking(context) {
    return new Promise((resolve, reject) => {
      apiGet('people/me/start_working', { api_token: context.state.apiToken })
        .then((response) => {
          if (!response.success) {
            throw new Error();
          }

          context.commit('startWorking');

          resolve();
        })
        .catch((error) => {
          console.warn(error);
          reject();
        });
    });
  },

  finishWorking(context) {
    return new Promise((resolve, reject) => {
      apiGet('people/me/finish_working', { api_token: context.state.apiToken })
        .then(() => {
          context.commit('finishWorking');

          resolve();
        })
        .catch((error) => {
          console.warn(error);
          reject();
        });
    });
  },

  sendJob(context, { type, notConference, alreadyHappened, skip, saveDetails, saveContacts, aggregator, report }) {
    return new Promise((resolve, reject) => {
      const jobId = context.state.job.id;

      const jobData = { type };
      if (notConference) {
        jobData.action = 'not_conference';
      } else if (skip) {
        jobData.action = 'skip';
      } else if (alreadyHappened) {
        jobData.action = 'already_happened';
      } else if (aggregator) {
        jobData.action = 'aggregator';
      } else if (report) {
        jobData.action = 'report';
        jobData.report = report;
      } else if (saveDetails) {
        jobData.action = 'save_details';
        jobData.data = { details: context.state.job.details };
      } else if (saveContacts) {
        jobData.action = 'save_contacts';
        jobData.data = { details: context.state.job.details, contacts: context.state.job.contacts };
      }

      const data = {
        api_token: context.state.apiToken,
        job_data: jobData,
      };

      apiPost(`jobs/${jobId}`, data)
        .then((response) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.warn(error);
          reject();
        });
    });
  },

  addEmptyContact(context) {
    return new Promise((resolve) => {
      context.commit('addEmptyContact');
      resolve();
    });
  },

  removeContact(context, index) {
    return new Promise((resolve) => {
      context.commit('removeContact', index);
      resolve();
    });
  },

  setJobField(context, { field, value }) {
    return new Promise((resolve) => {
      context.commit('setJobField', { field, value });
      resolve();
    });
  },

  setJobFieldError(context, { field, error }) {
    return new Promise((resolve) => {
      context.commit('setJobFieldError', { field, error });
      resolve();
    });
  },

  setJobContactField(context, { field, value }) {
    return new Promise((resolve) => {
      context.commit('setJobContactField', { field, value });
      resolve();
    });
  },

  setJobContactFieldError(context, { contact, field, error }) {
    return new Promise((resolve) => {
      context.commit('setJobContactFieldError', { contact, field, error });
      resolve();
    });
  },

  reset(context) {
    context.commit('reset');
  },
};

<template>
  <div class="d-flex justify-space-between align-center mb-4" style="height: 30px;">
    <div class="flex-grow-1" />
    <slot />
    <div class="font-weight-bold levelBadge mx-2">
      <div class="levelBadge__content body-2">
        {{ personNameInitials }}
      </div>
    </div>
    <div class="body-1">
      {{ personName }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      personName: (s) => s.person.name,
    }),

    personNameInitials() {
      const parts = this.personName
        .split(' ')
        .map((p) => p.trim().toUpperCase())
        .filter((p) => p.length > 0);
      let initials = '';
      if (parts.length > 0) {
        initials += parts[0][0];
        if (parts.length > 1) {
          initials += parts[parts.length - 1][0];
        }
      } else {
        initials = 'YO';
      }
      return initials;
    },
  },
};
</script>

<style lang="postcss">
.levelBadge {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #7f54f8;
  border-radius: 50%;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
  }

  &__number {
    position: absolute;
    right: -3px;
    bottom: -3px;
    width: 14px;
    height: 14px;
    border: 1px solid #7f54f8;
    background-color: #fff;
    border-radius: 50%;
  }

  &__numberContent {
    position: absolute;
    top: 50%;
    left: 50%;
    color: rgba(127, 84, 248, 1);
    transform: translate(-50%, -50%);
  }
}
</style>

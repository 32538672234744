import { render, staticRenderFns } from "./InfoBox.vue?vue&type=template&id=4f9ebe27&"
var script = {}
import style0 from "./InfoBox.vue?vue&type=style&index=0&id=4f9ebe27&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
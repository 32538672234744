<template>
  <v-main class="pa-0">
    <v-form ref="form" class="d-flex flex-column" style="padding-bottom: 144px">
      <slot
        :skipping="skipping"
        :skipped="skipped"
        :reporting="reporting"
        :reported="reported"
        :sending="sending"
        :sent="sent"
        :send-not-conference="sendNotConference"
        :send-already-happened="sendAlreadyHappened"
        :send-custom-report="sendCustomReport"
        :skip="skip"
        :send="send"
      />

      <JobFooter
        :skipping="skipping"
        :sending="sending"
        :disabled="reporting"
        :reward="reward"
        :send-text="sendText"
        @skip="skip"
        @send="send"
      />

      <DuplicateEntryWarningPopup ref="duplicateEntryWarningPopup" @close="closeDuplicateEntryWarning" />
    </v-form>
  </v-main>
</template>

<script>
import BrowserAddonController from '@/browser_addon_controller';

import JobFooter from '@/components/JobFooter.vue';
import DuplicateEntryWarningPopup from '@/components/DuplicateEntryWarningPopup.vue';

export default {
  components: {
    JobFooter,
    DuplicateEntryWarningPopup,
  },

  props: {
    jobType: { type: String, default: 'details' },
    reward: { type: Number, default: 0 },
    sendText: { type: String, default: 'Send' },
  },

  data() {
    return {
      skipping: false,
      skipped: false,
      reporting: false,
      reported: false,
      sending: false,
      sent: false,
    };
  },

  computed: {
    anyActionInProgress() {
      return this.skipping || this.sending || this.reporting;
    },
  },

  mounted() {
    BrowserAddonController.instance().makeAddonWindowNarrow();
    BrowserAddonController.instance().enableSelectionTool(this.jobType);
  },

  methods: {
    sendNotConference() {
      this.reporting = true;
      this.doSendJob({ notConference: true });
    },

    sendAlreadyHappened() {
      this.reporting = true;
      this.doSendJob({ alreadyHappened: true });
    },

    sendCustomReport(reason) {
      this.reporting = true;
      this.doSendJob({ report: reason });
    },

    skip() {
      this.skipping = true;
      this.doSendJob({ skip: true });
    },

    send() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.sending = true;
      this.doSendJob({ saveDetails: this.jobType === 'details', saveContacts: this.jobType === 'contacts' });
    },

    doSendJob({ notConference, skip, alreadyHappened, saveContacts, saveDetails, aggregator, report }) {
      const params = {
        type: this.jobType,
        notConference: !!notConference,
        skip: !!skip,
        alreadyHappened: !!alreadyHappened,
        saveContacts: !!saveContacts,
        saveDetails: !!saveDetails,
        aggregator: !!aggregator,
        report,
      };

      this.resetFormErrors();
      this.$store.commit('clearGlobalError');
      this.$store.commit('clearJobErrors');

      this.$store
        .dispatch('sendJob', params)
        .then((data) => {
          if (data.result === 'skip') {
            this.showSkipped();
          } else if (data.result === 'report') {
            this.showReported(data);
          } else if (data.result === 'details') {
            this.showDetails();
          } else if (data.result === 'find_contacts') {
            this.showFindContacts(data);
          } else if (data.result === 'done') {
            this.showDone(data);
          } else if (
            data.result === 'skip_duplicate_title' ||
            data.result === 'skip_duplicate_organizer' ||
            data.result === 'skip_duplicate_contact'
          ) {
            this.showDuplicateEntryWarning();
          }
        })
        .catch((response) => {
          console.warn(response);

          if (response?.error === 'invalid_from_date') {
            this.$store.commit('setJobFieldError', { field: 'fromDate', error: 'Neplatný datum.' });
          } else if (response?.error === 'invalid_to_date') {
            this.$store.commit('setJobFieldError', { field: 'toDate', error: 'Neplatný datum.' });
          } else if (response?.error === 'blacklisted_url') {
            this.$store.commit('setJobFieldError', {
              field: 'link',
              error: 'Zablokovaný odkaz. Vložte správný odkaz.',
            });
          } else if (response?.error === 'event_aggregator_url') {
            this.$store.commit('setJobFieldError', {
              field: 'link',
              error: 'Odkaz vede na stránku agregátoru konferencí. Vložte správný odkaz.',
            });
          } else if (response?.error === 'invalid_email') {
            this.$store.commit('setJobContactFieldError', {
              contact: response.data.contact,
              field: 'email',
              error: 'Neplatný e-mail.',
            });
          } else if (response?.error === 'banned_email') {
            this.$store.commit('setJobContactFieldError', {
              contact: response.data.contact,
              field: 'email',
              error: 'Zablokovaný e-mail. Vložte správný e-mail.',
            });
          } else if (response?.error === 'custom') {
            this.$store.commit('showGlobalError', response.message);
          } else {
            this.$store.commit('showGlobalError', 'Neznámá chyba. Kontaktujte podporu.');
          }

          this.resetSendingVariables();
        });
    },

    resetSendingVariables() {
      this.reporting = false;
      this.reported = false;
      this.skipping = false;
      this.skipped = false;
      this.sending = false;
      this.sent = false;
    },

    resetFormErrors() {
      this.$refs.form.resetValidation();
    },

    finishWorking() {
      this.$store.dispatch('finishWorking').then(() => {
        this.$store.commit('goTo', '/jobs_summary');
      });
    },

    showSkipped() {
      this.skipped = true;

      setTimeout(() => this.loadNextJob(), 500);
    },

    showReported(data) {
      this.reported = true;

      this.addSessionJobDone(data);

      setTimeout(() => this.loadNextJob(), 500);
    },

    showDetails() {
      this.resetFormErrors();
      this.resetSendingVariables();
      this.$store.commit('goTo', '/jobs/details');
    },

    showFindContacts(data) {
      this.addSessionJobDone(data);

      this.resetFormErrors();
      this.resetSendingVariables();
      this.$store.commit('goTo', '/jobs/contacts');
    },

    showDone(data) {
      this.sent = true;

      this.$store.dispatch('updatePerson');
      this.$store.dispatch('updateJobPool');

      this.addSessionJobDone(data);

      setTimeout(() => {
        this.loadNextJob();
      }, 500);
    },

    addSessionJobDone(data) {
      if (data.reward) {
        this.$store.dispatch('addSessionJobDone', {
          jobId: data.job_id,
          baseReward: data.reward.base,
          contactsReward: data.reward.contacts,
          bonus: data.reward.bonus,
          level: data.reward.level,
        });
      }
    },

    loadNextJob() {
      this.$store.dispatch('nextJob').then((result) => {
        if (!result.hasJob) {
          this.finishWorking();
          return;
        }

        this.resetFormErrors();
        this.resetSendingVariables();
        this.$store.commit('goTo', result.path);
      });
    },

    showDuplicateEntryWarning() {
      this.$refs.duplicateEntryWarningPopup.show();
    },

    closeDuplicateEntryWarning() {
      this.$refs.duplicateEntryWarningPopup.hide();
      this.loadNextJob();
    },
  },
};
</script>

<template>
  <v-main class="pa-0">
    <v-form ref="form" @submit="login">
      <v-container class="pa-5 pt-12">
        <div class="title mb-3">Přihlášení</div>

        <div v-show="errorMessage && errorMessage != ''" class="body-2 error--text text--darken-1 mb-6">
          {{ errorMessage }}
        </div>

        <v-text-field
          v-model="email"
          :error="signInFailed"
          :rules="[rules.required]"
          label="E-mail"
          class="mb-2"
          outlined
          dense
          hide-details
          autocomplete="username"
        />
        <v-text-field
          v-model="password"
          :error="signInFailed"
          :rules="[rules.required]"
          type="password"
          label="Heslo"
          class="mb-3"
          outlined
          dense
          hide-details
          autocomplete="current-password"
        />

        <div class="d-flex justify-space-between align-center">
          <v-btn text href="https://slideslive.com/password/reset" target="_blank" rel="noopener noreferrer">
            Zapomněl jsem heslo
          </v-btn>
          <v-btn type="submit" color="primary" :loading="loading"> Přihlásit </v-btn>
        </div>
      </v-container>
    </v-form>
  </v-main>
</template>

<script>
import BrowserAddonController from '@/browser_addon_controller';
import rules from '@/helpers/rules';

export default {
  data() {
    return {
      loading: false,
      signInFailed: false,
      errorMessage: '',
      email: '',
      password: '',
      rules,
    };
  },

  mounted() {
    BrowserAddonController.instance().makeAddonWindowNarrow();
  },

  methods: {
    login(event) {
      event.preventDefault();

      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      this.signInFailed = false;

      BrowserAddonController.instance()
        .version()
        .then((addon) => {
          this.$store
            .dispatch('signIn', {
              email: this.email,
              password: this.password,
              addonVersion: addon.version,
              frontendVersion: 0,
            })
            .then(() => {
              this.loading = false;

              this.$store.commit('goTo', '/home');
            })
            .catch((error) => {
              this.loading = false;

              if (error.message === 'SignInError') {
                if (error.response.error === 'invalid_email_or_password') {
                  this.signInFailed = true;
                  this.errorMessage = 'Špatný e-mail nebo heslo.';
                } else if (error.response.error === 'not_permitted') {
                  this.errorMessage = 'Účet nemá přístup do Kamina.';
                } else if (error.response.error === 'closed_for_public') {
                  this.errorMessage = 'Kamino je v tuto chvíli uzavřené, probíhá údržba. Zkuste to prosím později.';
                } else if (
                  error.response.error === 'unknown_addon_version' ||
                  error.response.error === 'unsupported_addon_version'
                ) {
                  this.errorMessage = 'Kamino doplněk v prohlížeči je zastaralý. Proveďte jeho aktualizaci.';
                } else if (error.response.error === 'custom') {
                  this.errorMessage = error.response.message;
                } else {
                  this.errorMessage = 'Přihlášení se nezdařilo. Kontaktujte podporu.';
                }
              } else if (error.response && error.response.error === 'custom') {
                this.errorMessage = error.response.message;
              } else {
                this.errorMessage = 'Neznámá chyba. Zkuste to znovu později.';
              }
            });
        });
    },
  },
};
</script>

import { getField } from 'vuex-map-fields';

import colors from 'vuetify/lib/util/colors';

import isContactGeneric from '@/helpers/is_contact_generic';

export default {
  getField,

  sessionJobsDone(s) {
    return Object.keys(s.session.finishedJobs).length;
  },

  sessionBaseReward(s) {
    return Object.values(s.session.finishedJobs)
      .map((r) => r.baseReward)
      .reduce((a, b) => a + b, 0);
  },

  sessionContactsReward(s) {
    return Object.values(s.session.finishedJobs)
      .map((r) => r.contactsReward)
      .reduce((a, b) => a + b, 0);
  },

  sessionLevelFlatBonus(s) {
    return Object.values(s.session.finishedJobs)
      .map((r) => r.levelFlatBonus)
      .reduce((a, b) => a + b, 0);
  },

  sessionTotalReward(s, g) {
    return g.sessionBaseReward + g.sessionContactsReward + g.sessionLevelFlatBonus;
  },

  jobReportReward(s) {
    const reward = s.job.reportReward || 0;
    return Math.round((reward * (100 + s.person.level)) / 100.0);
  },

  jobDetailsReward(s) {
    return Math.round((s.job.reward * (100 + s.person.level)) / 100.0);
  },

  jobContactsReward(s) {
    const rewardedContacts = Math.min(s.job.contacts.length, s.job.contactRewards.length);
    let reward = 0;
    for (let i = 0; i < rewardedContacts; ++i) {
      if (!s.job.contacts[i].email || s.job.contacts[i].email.indexOf('@') < 0) continue;
      if (!s.job.contacts[i].link || s.job.contacts[i].link === '') continue;

      const contactReward = s.job.contactRewards[i];
      const genericContactPenalty = isContactGeneric(s.job.contacts[i], s.job.genericContactEmailPatterns)
        ? contactReward * s.job.genericContactPenaltyPct
        : 0;
      reward += s.job.contactRewards[i] - genericContactPenalty;
    }

    return Math.round((reward * (100 + s.person.level)) / 100.0);
  },

  jobTotalReward(s, g) {
    return g.jobDetailsReward + g.jobContactsReward;
  },

  jobNextContactReward(s) {
    const nextContactIndex = s.job.contacts.length;
    if (nextContactIndex >= s.job.contactRewards.length) {
      return 0;
    }

    return Math.round((s.job.contactRewards[nextContactIndex] * (100 + s.person.level)) / 100.0);
  },

  jobGenericContactEmailPatterns(s) {
    return s.job.genericContactEmailPatterns;
  },

  jobPoolJobsTotal(s) {
    return s.jobPool.jobsTotal;
  },
  jobPoolJobsDone(s) {
    return s.jobPool.jobsDone;
  },
  jobPoolJobsRemaining(s, g) {
    return g.jobPoolJobsTotal - g.jobPoolJobsDone;
  },

  jobPoolPublishedAt(s) {
    return s.jobPool.publishedAt;
  },

  jobPoolTotalEarned(s) {
    return s.jobPool.earnedTotal;
  },

  jobPoolChartSeries(s, g) {
    return {
      Hotovo: {
        value: g.jobPoolJobsDone,
        colors: {
          bg: '#8351F9',
          primary: '#FFFFFF',
          secondary: '#FFFFFF80',
        },
      },
      Zbývá: {
        value: g.jobPoolJobsRemaining,
        colors: {
          bg: colors.grey.lighten3,
          primary: colors.grey.darken2,
          secondary: colors.grey.base,
        },
      },
    };
  },

  jobPoolWorkingPeople(s) {
    return s.jobPool.workingPeople;
  },

  payout(s) {
    return s.person.payout;
  },

  jobsVerifying(s) {
    return s.person.jobsVerifying;
  },

  jobsVerifyingEarned(s) {
    return s.person.jobsVerifyingEarned;
  },
};

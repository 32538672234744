<template>
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-card color="white">
      <v-card-text class="py-5 text--primary">
        <div class="title pb-5">Dnes jste udělali {{ sessionJobsDone }} úkolů</div>

        <div class="d-flex justify-space-between align-center">
          <PoolPieChart hide-label :size="80" />
          <div class="ml-5">
            <div class="body-2 font-weight-bold">Opravdu chcete skončit?</div>
            <div class="body-2 font-weight-bold text--secondary">
              V balíku úkolů je ještě {{ jobPoolJobsRemaining }} úkolů.
            </div>
          </div>
          <div class="flex-grow-1" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn text :disabled="loading" @click="continueWorking">Pokračovat v práci</v-btn>
        <div class="flex-grow-1" />
        <v-btn color="primary" :loading="loading" @click="finishWorking">Ukončit práci</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import PoolPieChart from './PoolPieChart.vue';

export default {
  components: {
    PoolPieChart,
  },

  data() {
    return {
      dialog: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['jobPoolJobsRemaining', 'sessionJobsDone']),
  },

  methods: {
    show() {
      this.dialog = true;
      this.loading = false;
    },

    continueWorking() {
      this.dialog = false;
    },

    finishWorking() {
      this.loading = true;

      this.$store.dispatch('finishWorking').then(() => {
        Promise.all([this.$store.dispatch('updatePerson'), this.$store.dispatch('updateJobPool')]).then(() => {
          this.dialog = false;
          this.loading = false;

          this.$store.commit('goTo', '/jobs_summary');
        });
      });
    },
  },
};
</script>

<template>
  <v-main class="pa-0">
    <v-container class="px-10 py-5">
      <NameHeader />

      <div class="d-flex justify-space-between align-center mb-4">
        <div class="headline">Vyhodnocení</div>
        <div class="flex-grow-1" />
        <v-btn outlined @click="home">Zavřít vyhodnocení</v-btn>
      </div>

      <div class="body-1 text--secondary mb-6">
        Pracoval(a) jsi
        <span class="font-weight-bold">{{ sessionDurationString }}</span>
      </div>

      <div class="d-flex" style="width: 100%;">
        <InfoBox>
          <template #title>Vypracoval(a) jsi</template>

          <div class="d-flex align-center py-10 px-8 mr-2">
            <div class="infoBoxEarned__jobs orange--text display-2 font-weight-bold">
              {{ sessionJobsDone }}
              <span class="text-uppercase body-2 font-weight-bold">úkolů</span>
            </div>
            <MoneyBox
              up-to
              class="ml-10"
              :base-reward="sessionBaseReward"
              :emails-reward="sessionContactsReward"
              :level-flat-bonus="sessionLevelFlatBonus"
              show-level-flat-bonus
              show-emails-reward
            />
          </div>
        </InfoBox>

        <InfoBox class="flex-grow-1 ml-6">
          <template #title>Úroveň</template>

          <div class="text-uppercase subtitle-1 font-weight-bold">Úroveň {{ level }}</div>
          <div class="text-uppercase text--secondary subtitle-1 font-weight-bold mb-2">
            Do další úrovně zbývá {{ jobsToNextLevel - jobsDoneThisLevel }} úkolů
          </div>
          <LevelBar :min-width="280" :highlight-jobs="sessionJobsDone" />
        </InfoBox>
      </div>

      <div class="d-flex justify-space-between align-center mt-8">
        <div class="flex-grow-1" />

        <PoolPieChart :max-width="250" />

        <div class="text-left ml-10">
          <div class="title font-weight-bold">Balík úkolů</div>
          <table class="body-2">
            <tr>
              <td class="">Zveřejněno:</td>
              <td class="pl-2">{{ jobPoolPublishedAt }}</td>
            </tr>
            <tr>
              <td class="">Celkem úkolů:</td>
              <td class="pl-2">{{ jobPoolJobsTotal }}</td>
            </tr>
          </table>
        </div>

        <div class="flex-grow-1" />
      </div>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import formatSeconds from '@/helpers/format_seconds';

import BrowserAddonController from '@/browser_addon_controller';
import PoolPieChart from '@/components/PoolPieChart.vue';
import MoneyBox from '@/components/MoneyBox.vue';
import LevelBar from '@/components/LevelBar.vue';
import InfoBox from '@/components/InfoBox.vue';
import NameHeader from '@/components/NameHeader.vue';

export default {
  components: {
    PoolPieChart,
    MoneyBox,
    LevelBar,
    InfoBox,
    NameHeader,
  },

  data() {
    return {
      sessionStartedAtOnMount: null,
    };
  },

  computed: {
    ...mapState({
      sessionStartedAt: (s) => s.session.startedAt,

      level: (state) => state.person.level,
      jobsDoneThisLevel: (state) => state.person.jobsDoneThisLevel,
      jobsToNextLevel: (state) => state.person.jobsToNextLevel,
    }),

    ...mapGetters({
      jobPoolJobsTotal: 'jobPoolJobsTotal',
      jobPoolPublishedAt: 'jobPoolPublishedAt',
      sessionJobsDone: 'sessionJobsDone',
      sessionBaseReward: 'sessionBaseReward',
      sessionContactsReward: 'sessionContactsReward',
      sessionLevelFlatBonus: 'sessionLevelFlatBonus',
    }),

    sessionDurationString() {
      return formatSeconds((new Date().getTime() - this.sessionStartedAt) / 1000);
    },
  },

  mounted() {
    BrowserAddonController.instance().makeAddonWindowWide();
    BrowserAddonController.instance().disableSelectionTool();
  },

  methods: {
    home() {
      this.$store.commit('goTo', '/home');
    },
  },
};
</script>

<style lang="postcss">
.jobName {
  overflow: hidden;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shrink {
  width: 1px;
  white-space: nowrap;
}

.infoBoxEarned {
  &__jobs {
    position: relative;

    span {
      position: relative;
      top: 7px;
      vertical-align: text-top;
    }
  }
}
</style>

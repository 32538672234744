<template>
  <div class="d-flex justify-space-between align-center levelBar" :style="style">
    <div class="flex-grow-1 levelBar__left">
      <div class="levelBar__progress">
        <div
          v-if="highlightJobs > 0"
          class="levelBar__highlightedProgressValue"
          :style="`width: ${highlightedProgressPct}%`"
        />
        <div class="levelBar__progressValue" :style="`width: ${progressPct}%`" />
      </div>

      <div class="levelBar__badge">
        <span class="text-center body-2 font-weight-bold primary--text levelBar__badgeText">{{ level }}</span>
      </div>
    </div>
    <div class="ml-4 title font-weight-bold primary--text levelBar__text">
      <span class="levelBar__doneThisLevel">
        <div class="levelBar__plusOne green--text">+1</div>
        {{ jobsDoneThisLevel }}
      </span>
      /{{ jobsToNextLevel }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    minWidth: { type: Number, default: 280 },
    highlightJobs: { type: Number, default: 0 },
    enableLevelUpAnimation: { type: Boolean },
  },

  data() {
    return {
      prevLevel: null,
      prevJobsToNextLevel: null,
      jobDoneAnimation: false,
      jobDoneAnimationStep: null,
      levelUpAnimation: false,
      levelUpAnimationStep: null,
    };
  },

  computed: {
    ...mapState({
      stateLevel: (state) => state.person.level,
      stateJobsDoneThisLevel: (state) => state.person.jobsDoneThisLevel,
      stateJobsToNextLevel: (state) => state.person.jobsToNextLevel,
    }),

    level() {
      if (!this.levelUpAnimation) return this.stateLevel;

      if (this.levelUpAnimationStep < 3) return this.prevLevel;
      return this.stateLevel;
    },

    jobsDoneThisLevel() {
      if (!this.levelUpAnimation && !this.jobDoneAnimation) return this.stateJobsDoneThisLevel;

      if (this.levelUpAnimation) {
        if (this.levelUpAnimationStep === 1) {
          return (this.prevJobsToNextLevel || this.stateJobsToNextLevel) - 1;
        }
        if (this.levelUpAnimationStep < 3) {
          return this.prevJobsToNextLevel || this.stateJobsToNextLevel;
        }
      }

      if (this.jobDoneAnimation) {
        if (this.jobDoneAnimationStep === 1) return this.stateJobsDoneThisLevel - 1;
      }

      return this.stateJobsDoneThisLevel;
    },

    jobsToNextLevel() {
      if (!this.levelUpAnimation) return this.stateJobsToNextLevel;

      if (this.levelUpAnimationStep < 3) {
        return this.prevJobsToNextLevel || this.stateJobsToNextLevel;
      }
      return this.stateJobsToNextLevel;
    },

    progressPct() {
      return (Math.max(0, this.jobsDoneThisLevel - this.highlightJobs) / this.jobsToNextLevel) * 100;
    },

    highlightedProgressPct() {
      return (this.jobsDoneThisLevel / this.jobsToNextLevel) * 100;
    },

    style() {
      let style = '';
      if (this.minWidth) {
        style += `min-width: ${this.minWidth}px;`;
      }
      return style;
    },
  },

  watch: {
    stateLevel(newLevel, oldLevel) {
      if (!this.enableLevelUpAnimation) return;

      if (newLevel > oldLevel) {
        this.prevLevel = oldLevel;
        this.showLevelUpAnimation();
      }
    },

    stateJobsToNextLevel(newValue, oldValue) {
      this.prevJobsToNextLevel = oldValue;
    },

    stateJobsDoneThisLevel(newValue, oldValue) {
      if (!this.enableLevelUpAnimation) return;

      if (newValue > oldValue) {
        this.showJobDoneAnimation();
      }
    },
  },

  mounted() {},

  methods: {
    showJobDoneAnimation() {
      this.jobDoneAnimation = true;
      this.setJobDoneAnimationStep(1);
    },

    setJobDoneAnimationStep(step) {
      this.jobDoneAnimationStep = step;

      if (step > 1) {
        this.$el.classList.remove(`levelBar--jobDone-${step - 1}`);
      }

      if (step <= 2) {
        this.$el.classList.add(`levelBar--jobDone-${step}`);

        setTimeout(() => this.setJobDoneAnimationStep(step + 1), 700);
      } else {
        this.jobDoneAnimation = false;
        this.$emit('animationFinished');
      }
    },

    showLevelUpAnimation() {
      this.levelUpAnimation = true;
      this.setLevelUpAnimationStep(1);
    },

    setLevelUpAnimationStep(step) {
      this.levelUpAnimationStep = step;

      if (step > 1) {
        this.$el.classList.remove(`levelBar--up-${step - 1}`);
      }

      if (step <= 4) {
        this.$el.classList.add(`levelBar--up-${step}`);

        setTimeout(() => this.setLevelUpAnimationStep(step + 1), 700);
      } else {
        this.levelUpAnimation = false;
        this.prevLevel = null;
        this.prevJobsToNextLevel = null;
        this.$emit('animationFinished');
      }
    },
  },
};
</script>

<style lang="postcss">
.levelBar {
  &__left {
    position: relative;
  }

  &__progress {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    left: 0;
    height: 10px;
    margin-left: 25px;
    background-color: #eee;
    border-radius: 5px;
    transform: translateY(-50%);
  }

  &__progressValue,
  &__highlightedProgressValue {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    border-radius: 5px;
  }

  &__progressValue {
    background-color: #8351f9;
  }

  &__highlightedProgressValue {
    background-color: #ff9800;
  }

  &__badge {
    position: relative;
    z-index: 2;
    left: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #8351f9;
    background-color: white;
    border-radius: 50%;
  }

  &__badgeText {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
  }

  &__text {
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: 0 0 6px #00000029;
  }

  &__doneThisLevel {
    position: relative;
  }

  &__plusOne {
    position: absolute;
    top: -15px;
    left: 50%;
    font-size: 13px;
    font-weight: bold;
    opacity: 0;
    transform: translateX(-50%) translateY(11px);
  }

  &__doneThisLevel,
  &__text,
  &__badgeText {
    transition: color 0.2s ease-in;
  }

  &__progressValue,
  &__highlightedProgressValue {
    transition: width 0.2s ease-in, background-color 0.2s ease-in;
  }

  &__badge {
    transition: border-color 0.2s ease-in, transform 0.2s ease-in;
  }

  &__plusOne {
    transition: opacity 0.2s ease-in, transform 0.2s ease-in;
  }

  &--jobDone-1 {
    .levelBar {
      &__doneThisLevel {
        color: #4caf50;
      }

      &__plusOne {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
      }
    }
  }

  &--jobDone-2 {
    .levelBar {
      &__doneThisLevel {
        color: #4caf50;
      }

      &__progressValue,
      &__highlightedProgressValue {
        background-color: #4caf50;
      }
    }
  }

  &--up-1 {
    .levelBar {
      &__doneThisLevel {
        color: #4caf50;
      }

      &__plusOne {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
      }
    }
  }

  &--up-2 {
    .levelBar {
      &__doneThisLevel {
        color: #4caf50;
      }

      &__text,
      &__badgeText {
        color: #4caf50;
      }

      &__progressValue,
      &__highlightedProgressValue {
        background-color: #4caf50;
      }

      &__badge {
        border-color: #4caf50;
      }
    }
  }

  &--up-3 {
    .levelBar {
      &__badge {
        border-color: #4caf50;
        transform: scale(1.6);
      }

      &__badgeText,
      &__doneThisLevel {
        color: #4caf50;
      }
    }
  }

  &--up-4 {
    .levelBar {
      &__badge {
        border-color: #4caf50;
      }

      &__badgeText,
      &__doneThisLevel {
        color: #4caf50;
      }
    }
  }
}
</style>

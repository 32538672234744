<template>
  <v-footer app color="white" class="px-3 py-6 pb-2">
    <slot name="top" />
    <div class="d-flex flex-column align-center mx-auto g-2">
      <div>
        <div class="text-center">
          <div class="amber rounded-t d-inline-block px-3 py-1 font-weight-bold">
            + {{ (reward / 100.0).toFixed(2) }} Kč
          </div>
        </div>
        <v-btn color="primary" :loading="sending" :disabled="skipping || disabled" @click="$emit('send')">
          {{ sendText }}
        </v-btn>
      </div>
      <v-btn text :loading="skipping" :disabled="sending || disabled" @click="$emit('skip')">
        Přeskočit
      </v-btn>
    </div>
  </v-footer>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    skipping: Boolean,
    sending: Boolean,
    reward: { type: Number, default: 0 },
    sendText: { type: String, default: 'Send' },
  },
};
</script>

import { updateField } from 'vuex-map-fields';
import * as chrono from 'chrono-node';

import initialState from './state';

const EmptyJobContactData = {
  name: '',
  email: '',
  title: '',
  link: '',
};

const EmptyJobContactErrorsData = {
  name: '',
  email: '',
  title: '',
  link: '',
};

const EmptyJobData = {
  link: '',
  organizer: '',
  title: '',
  fromDate: '',
  toDate: '',
  virtualConference: false,
  location: '',
};

const EmptyJobErrorsData = {
  link: '',
  organizer: '',
  title: '',
  fromDate: '',
  toDate: '',
  virtualConference: '',
  location: '',
};

export default {
  updateField,

  setEnabled(state, enabled) {
    state.enabled = enabled;
  },

  showGlobalError(state, error) {
    state.globalError = error;
  },

  clearGlobalError(state) {
    state.globalError = null;
  },

  goTo(state, route) {
    state.route = route;
  },

  setApiToken(state, { apiToken }) {
    state.apiToken = apiToken;
  },

  resetApiToken(state) {
    state.apiToken = null;
  },

  setPerson(state, person) {
    state.person.email = person.email;

    state.person.name = person.name;
    state.person.hello = person.hello;
    state.person.level = person.level;

    state.person.jobsDoneThisLevel = person.jobs_done_this_level;
    state.person.jobsToNextLevel = person.jobs_to_next_level;

    state.person.payout = person.payout;
    state.person.jobsVerifying = person.jobs_verifying;
    state.person.jobsVerifyingEarned = person.jobs_verifying_earned;
  },

  setJobPool(state, pool) {
    state.jobPool.id = pool.id;
    state.jobPool.publishedAt = pool.published_at;
    state.jobPool.jobsTotal = pool.jobs_total;
    state.jobPool.jobsDone = pool.jobs_done;
    state.jobPool.earnedTotal = pool.earned_total;
    state.jobPool.workingPeople = pool.working_people;
  },

  startWorking(state) {
    state.session.working = true;
    state.session.startedAt = new Date().getTime();
    state.session.duration = 0;
    state.session.finishedJobs = {};
  },

  finishWorking(state) {
    state.session.working = false;
  },

  setSessionDuration(state, duration) {
    state.session.duration = duration;
  },

  setJobDuration(state, duration) {
    state.job.duration = duration;
  },

  setJob(state, job) {
    state.job.startedAt = new Date().getTime();
    state.job.duration = 0;

    state.job.id = job.id;
    state.job.reportReward = job.report_reward;
    state.job.reward = job.reward;
    state.job.contactRewards = job.contact_rewards;

    state.job.details = { ...EmptyJobData, ...job.details };

    state.job.contacts = job.contacts || [];
    if (state.job.contacts.length < 1) {
      state.job.contacts.push({ ...EmptyJobContactData });
    }

    state.job.detailsErrors = { ...EmptyJobErrorsData };
    state.job.contactsErrors = state.job.contacts.map(() => ({ ...EmptyJobContactErrorsData }));

    state.job.genericContactPenaltyPct = job.generic_contact_penalty_pct;
    if (job.generic_contact_email_patterns) {
      state.job.genericContactEmailPatterns = job.generic_contact_email_patterns;
    } else {
      state.job.genericContactEmailPatterns = [];
    }

    state.jobContactsTab = 0;
  },

  addEmptyContact(state) {
    const contacts = [...state.job.contacts];
    contacts.push({ ...EmptyJobContactData });
    state.job.contacts = contacts;
  },

  removeContact(state, index) {
    const contacts = [...state.job.contacts];
    contacts.splice(index, 1);
    if (contacts.length < 1) {
      contacts.push({ ...EmptyJobContactData });
    }
    state.job.contacts = contacts;

    if (state.jobContactsTab >= contacts.length) {
      state.jobContactsTab = contacts.length - 1;
    }
  },

  addSessionJobDone(state, { jobId, baseReward, contactsReward, bonus, level }) {
    const base = Math.round((baseReward * (100 + bonus)) / 100.0);
    const contacts = Math.round((contactsReward * (100 + bonus)) / 100.0);
    const levelFlatBonus = Math.round(((base + contacts) * level) / 100.0);

    state.session.finishedJobs = {
      ...state.session.finishedJobs,
      [jobId]: {
        baseReward: base,
        contactsReward: contacts,
        levelFlatBonus,
      },
    };

    state.session.jobsDone += 1;

    state.session.baseReward += base;
    state.session.emailsReward += contacts;
    state.session.levelFlatBonus += levelFlatBonus;
  },

  setJobField(state, { field, value }) {
    if (field === 'fromDate' || field === 'toDate') {
      const p = chrono.parse(value, new Date(), { forwardDate: true });
      if (p.length > 0) {
        const s = p[0].start;
        const day = s.knownValues.day || s.impliedValues.day;
        const month = s.knownValues.month || s.impliedValues.month;
        const year = s.knownValues.year || s.impliedValues.year;

        value = `${day}/${month}/${year}`;
      }
    }

    state.job.details[field] = value;
  },

  setJobContactField(state, { field, value }) {
    const contacts = [...state.job.contacts];
    contacts[state.jobContactsTab][field] = value;
    state.job.contacts = contacts;
  },

  clearJobErrors(state) {
    state.job.detailsErrors = { ...EmptyJobErrorsData };
    state.job.contactsErrors = state.job.contacts.map(() => ({ ...EmptyJobContactErrorsData }));
  },

  setJobFieldError(state, { field, error }) {
    state.job.detailsErrors[field] = error;
  },

  setJobContactFieldError(state, { contact, field, error }) {
    state.job.contactsErrors[contact][field] = error;
    state.jobContactsTab = contact;
  },

  reset(state) {
    Object.assign(state, initialState);
  },
};

<template>
  <div>
    <div class="py-4 px-6 grey lighten-4 jobPageHeader">
      <div class="d-flex g-4 align-center">
        <PoolPieChart :size="48" hide-label />

        <div class="d-flex g-1 body-2">
          <div>
            <div>Úkoly (dnes):</div>
            <div>Odměna (dnes):</div>
          </div>

          <div>
            <div class="font-weight-bold">{{ sessionJobsDone }}</div>
            <div class="font-weight-bold" style="position: relative;">
              {{ (sessionTotalReward / 100.0).toFixed(2) }} Kč
              <div
                ref="rewardChange"
                class="amber rounded"
                style="
                  opacity: 0;
                  position: absolute;
                  top: -1px;
                  left: 0;
                  white-space: nowrap;
                  padding: 2px 5px;
                  transition: opacity 0.2s ease-in;
                "
              >
                + <span ref="rewardChangeAmount">0.00</span> Kč
              </div>
            </div>
          </div>
        </div>

        <div class="ml-auto">
          <v-btn :disabled="disabled" text @click="$emit('finishWorking')">
            Ukončit
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PoolPieChart from './PoolPieChart.vue';

export default {
  components: {
    PoolPieChart,
  },

  props: {
    disabled: Boolean,
  },

  data() {
    return {
      hideRewardChangeTimeout: null,
    };
  },

  computed: {
    ...mapGetters({ sessionJobsDone: 'sessionJobsDone', sessionTotalReward: 'sessionTotalReward' }),
  },

  watch: {
    sessionTotalReward(newReward, oldReward) {
      if (!this.$refs.rewardChange) return;

      const change = newReward - oldReward;
      if (change <= 0) return;

      this.$refs.rewardChangeAmount.textContent = (change / 100.0).toFixed(2);
      this.$refs.rewardChange.style.opacity = 1;

      if (this.hideRewardChangeTimeout) {
        clearTimeout(this.hideRewardChangeTimeout);
        this.hideRewardChangeTimeout = null;
      }

      this.hideRewardChangeTimeout = setTimeout(() => {
        this.$refs.rewardChange.style.opacity = 0;
      }, 3000);
    },
  },
};
</script>

<style lang="postcss">
.jobPageHeader {
  position: relative;
  z-index: 100;
}
</style>

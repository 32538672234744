import LoginPage from '@/views/Login.vue';
import HomePage from '@/views/Home.vue';
import AccountPage from '@/views/Account.vue';
import JobConferencePage from '@/views/JobConference.vue';
import JobContactsPage from '@/views/JobContacts.vue';
import JobsSummaryPage from '@/views/JobsSummary.vue';

export default [
  {
    path: '/',
    component: LoginPage,
    meta: {},
  },
  {
    path: '/home',
    component: HomePage,
    meta: {},
  },
  {
    path: '/account',
    component: AccountPage,
    meta: {},
  },
  {
    path: '/jobs/details',
    component: JobConferencePage,
    meta: { showJobHeader: true },
  },
  {
    path: '/jobs/contacts',
    component: JobContactsPage,
    meta: { showJobHeader: true },
  },
  {
    path: '/jobs_summary',
    component: JobsSummaryPage,
    meta: {},
  },
];

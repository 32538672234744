<template>
  <JobContacts ref="job" job-type="contacts" />
</template>

<script>
import JobContacts from '@/components/JobContacts.vue';

export default {
  components: {
    JobContacts,
  },
};
</script>

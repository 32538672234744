import * as chrono from 'chrono-node';

export default function parseFrontendDate(frontendDate) {
  const p = chrono.en.GB.parse(frontendDate, new Date(), { forwardDate: true });
  if (p.length > 0) {
    const s = p[0].start;
    const day = s.knownValues.day || s.impliedValues.day;
    const month = s.knownValues.month || s.impliedValues.month;
    const year = s.knownValues.year || s.impliedValues.year;

    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  return '';
}

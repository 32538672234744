<template>
  <div class="pa-4 g-2 d-flex flex-column rounded grey lighten-4">
    <v-text-field
      :value="link"
      label="Odkaz na web konference"
      outlined
      dense
      hide-details="auto"
      :error-messages="linkError"
      readonly
      disabled
    >
      <template #append>
        <v-icon color="primary" @click="openLink">
          mdi-open-in-new
        </v-icon>
      </template>
    </v-text-field>

    <div v-if="skipping && !skipped" class="text-center font-weight-bold primary--text">
      <div>Přeskakování&hellip;</div>
    </div>

    <div v-if="skipped" class="text-center font-weight-bold primary--text">
      <div>
        <v-icon size="20" class="primary--text" style="vertical-align: middle; margin-top: -2px;"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        Přeskočeno.
      </div>
    </div>

    <div v-if="reporting && !reported" class="text-center font-weight-bold primary--text">
      <div>Nahlašování&hellip;</div>
    </div>

    <div v-if="reported" class="text-center font-weight-bold primary--text">
      <div>
        <v-icon size="20" class="primary--text" style="vertical-align: middle; margin-top: -2px;"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        Nahlášeno.
      </div>
    </div>

    <div v-if="!anyActionInProgress" class="text-center">
      <div class="font-weight-bold">
        Nahlásit a přeskočit
        <template v-if="reportReward > 0">(+ {{ (reportReward / 100.0).toFixed(2) }} Kč) </template>
      </div>
      <div>
        <LinkButton @click="$emit('not-conference')">Není to web konference</LinkButton>
      </div>
      <div><LinkButton @click="$emit('already-happened')">Konference již proběhla</LinkButton></div>
      <div><LinkButton @click="openReportModal">Jiné&hellip;</LinkButton></div>
    </div>

    <ReportModal ref="reportModal" @report="sendCustomReport" />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import LinkButton from '@/components/LinkButton.vue';
import ReportModal from './ReportModal.vue';

export default {
  components: { LinkButton, ReportModal },

  props: {
    reporting: Boolean,
    reported: Boolean,
    skipping: Boolean,
    skipped: Boolean,
    reportReward: { type: Number, default: 0 },
  },

  computed: {
    anyActionInProgress() {
      return this.skipping || this.sending || this.reporting;
    },

    ...mapFields({
      link: 'job.details.link',
      organizer: 'job.details.organizer',
      title: 'job.details.title',
      fromDate: 'job.details.fromDate',
      toDate: 'job.details.toDate',
      location: 'job.details.location',

      linkError: 'job.detailsErrors.link',
      organizerError: 'job.detailsErrors.organizer',
      titleError: 'job.detailsErrors.title',
      fromDateError: 'job.detailsErrors.fromDate',
      toDateError: 'job.detailsErrors.toDate',
      locationError: 'job.detailsErrors.location',
    }),
  },

  methods: {
    openLink() {
      window.open(this.link, '_blank');
    },

    openReportModal() {
      this.$refs.reportModal.show();
    },

    sendCustomReport(reason) {
      this.$emit('report', reason);
    },
  },
};
</script>

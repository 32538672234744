<template>
  <v-app>
    <GlobalError />
    <JobHeader v-if="$route.meta.showJobHeader" @finishWorking="showEndPoup" />

    <router-view />

    <EndPopup ref="endPopup" />
  </v-app>
</template>

<script>
import GlobalError from '@/components/GlobalError.vue';
import JobHeader from '@/components/JobHeader.vue';
import EndPopup from '@/components/EndPopup.vue';

export default {
  components: {
    GlobalError,
    JobHeader,
    EndPopup,
  },

  methods: {
    showEndPoup() {
      this.$refs.endPopup.show();
    },
  },
};
</script>

<style lang="postcss">
.v-content {
  max-height: 100vh;

  &__wrap {
    overflow: auto;
  }
}

.v-main {
  max-height: 100vh;
  padding: 0;
}

.v-text-field__details {
  margin-bottom: 0 !important;
}
</style>

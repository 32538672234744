<template>
  <v-main class="pa-0">
    <v-form>
      <v-container class="px-10 py-5">
        <NameHeader>
          <v-btn text :loading="accountLoading" @click="account">Můj účet</v-btn>
        </NameHeader>

        <div class="d-flex justify-space-between align-center mb-4 text--secondary">
          <div class="headline">
            Ahoj {{ personHello }}
            <v-icon class="text--secondary" size="32">mdi-emoticon-happy-outline</v-icon>
          </div>
          <div class="flex-grow-1" />
          <div>
            <LevelBar :min-width="300" />
          </div>
        </div>

        <div class="display-1 font-weight-bold text-center text--secondary mb-3">
          Zbývá: {{ jobPoolJobsRemaining }} úkolů
        </div>

        <div class="text-center mb-4">
          <span>Na tomto balíku si lidé už vydělali:</span>
          <br />
          <span class="font-weight-bold">{{ jobPoolTotalEarned / 100 }} Kč</span>
        </div>

        <PoolPieChart klass="mb-4 mx-auto" :size="280" />

        <table class="mx-auto mb-4 text-body-2">
          <tr>
            <td>Zveřejněno:</td>
            <td class="font-weight-bold pl-2">{{ jobPoolPublishedAt }}</td>
          </tr>
          <tr>
            <td>Celkem úkolů:</td>
            <td class="font-weight-bold pl-2">{{ jobPoolJobsTotal }}</td>
          </tr>
        </table>

        <div class="text-center mb-2">
          <v-btn ref="startButton" color="primary" :loading="startLoading" @click="start">Začít pracovat</v-btn>
        </div>

        <div class="text-center">
          <v-btn ref="signOutButton" text @click="signOut">Odhlásit</v-btn>
        </div>
      </v-container>
    </v-form>
  </v-main>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import BrowserAddonController from '@/browser_addon_controller';
import PoolPieChart from '@/components/PoolPieChart.vue';
import LevelBar from '@/components/LevelBar.vue';
import NameHeader from '@/components/NameHeader.vue';

export default {
  components: {
    PoolPieChart,
    LevelBar,
    NameHeader,
  },

  data() {
    return {
      startLoading: false,
      accountLoading: false,
    };
  },

  computed: {
    ...mapState({
      personHello: (state) => state.person.hello,
    }),
    ...mapGetters(['jobPoolJobsRemaining', 'jobPoolJobsTotal', 'jobPoolTotalEarned', 'jobPoolPublishedAt']),
  },

  mounted() {
    this.startLoading = false;
    this.accountLoading = false;

    BrowserAddonController.instance().makeAddonWindowWide();
  },

  methods: {
    account() {
      this.accountLoading = true;

      this.$store.dispatch('updatePerson').then(() => {
        this.accountLoading = false;
        this.$store.commit('goTo', '/account');
      });
    },

    start() {
      this.startLoading = true;

      this.$store
        .dispatch('startWorking')
        .then(() => this.$store.dispatch('nextJob'))
        .then((result) => {
          this.startLoading = false;

          if (result.hasJob) {
            this.$store.commit('goTo', result.path);
          }
        });
    },

    signOut() {
      this.$store.commit('reset');
    },
  },
};
</script>

<template>
  <PieChart
    :class="computedClass"
    :style="style"
    :data="poolChartSeries"
    :hide-label="hideLabel"
    :width="size"
    :height="size"
  />
</template>

<script>
import PieChart from './PieChart.vue';

export default {
  components: {
    PieChart,
  },

  props: {
    klass: { type: String, default: '' },
    size: { type: Number, default: 300 },
    hideLabel: Boolean,
  },

  computed: {
    style() {
      return `max-width: ${this.maxWidth}px`;
    },

    poolChartSeries() {
      return this.$store.getters.jobPoolChartSeries;
    },

    computedClass() {
      return `poolPieChart ${this.klass}`;
    },
  },

  mounted() {},
};
</script>

<style lang="postcss">
.poolPieChart {
  text-align: center;

  svg {
    vertical-align: middle;
  }
}
</style>

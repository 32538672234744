<template>
  <div class="d-flex">
    <div class="moneyBox moneyBox--orange">
      <div class="moneyBox__title">
        Základ
      </div>
      <div class="moneyBox__value">
        {{ (baseReward / 100).toFixed(2) }}
        <span class="moneyBox__value--top">Kč</span>
      </div>
    </div>
    <div v-if="showEmailsReward" class="moneyBox__op moneyBox__op--plus">
      <div class="moneyBox__opSign">
        +
      </div>
    </div>
    <div v-if="showEmailsReward" class="moneyBox moneyBox--grey">
      <div class="moneyBox__title">Odměna<br />za emaily</div>
      <div class="moneyBox__value">
        {{ (emailsReward / 100).toFixed(2) }}
        <span class="moneyBox__value--top">Kč</span>
      </div>
    </div>
    <div class="moneyBox__op moneyBox__op--plus">
      <div class="moneyBox__opSign">
        +
      </div>
    </div>
    <div v-if="!showLevelFlatBonus" class="moneyBox">
      <div class="moneyBox__title">Bonus za<br />{{ level }}. úroveň</div>
      <div class="moneyBox__value">{{ levelBonus }}<span class="moneyBox__value--top">%</span></div>
    </div>
    <div v-if="showLevelFlatBonus" class="moneyBox">
      <div class="moneyBox__title">Bonus za<br />úroveň</div>
      <div class="moneyBox__value">
        {{ (levelFlatBonus / 100.0).toFixed(2) }}
        <span class="moneyBox__value--top">Kč</span>
      </div>
    </div>
    <div class="moneyBox__op moneyBox__op--is">
      <div class="moneyBox__opSign">
        =
      </div>
    </div>
    <div class="moneyBox">
      <div v-if="upTo" class="moneyBox__title">
        Celkem až
      </div>
      <div v-if="!upTo" class="moneyBox__title">
        Celkem
      </div>
      <div class="moneyBox__value">
        {{ totalReward }}
        <span class="moneyBox__value--top">Kč</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    baseReward: {
      type: Number,
      default: 0,
    },
    emailsReward: {
      type: Number,
      default: 0,
    },
    showEmailsReward: {
      type: Boolean,
    },
    upTo: {
      type: Boolean,
    },
    showLevelFlatBonus: {
      type: Boolean,
    },
    levelFlatBonus: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapState({
      level: (state) => state.person.level,
      levelBonus: (state) => state.person.level,
    }),

    totalReward() {
      if (this.showLevelFlatBonus) {
        return ((this.baseReward + this.emailsReward + this.levelFlatBonus) / 100.0).toFixed(2);
      }

      const cents = ((this.baseReward + this.emailsReward) / 100.0) * (100 + this.levelBonus);
      return (cents / 100.0).toFixed(2);
    },
  },
};
</script>

<style lang="postcss">
.moneyBox__op {
  z-index: 2;

  &--plus {
    margin-right: -6px;
    margin-left: -6px;
  }

  &--is {
    margin-right: 4px;
    margin-left: 4px;
  }

  &Sign {
    width: 18px;
    height: 18px;
    margin-top: 31px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 6px #00000029;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
  }
}

.moneyBox {
  position: relative;
  z-index: 1;
  padding: 0 6px;
  border: 1px solid #8351f9;
  margin-top: 13px;
  border-radius: 4px;
  color: #8351f9;

  &--orange {
    border-color: orange;
    color: orange;
  }

  &--grey {
    border-color: #9e9e9e;
    color: #9e9e9e;
  }

  &__title {
    position: relative;
    display: table-cell;
    height: 26px;
    padding: 0 3px;
    margin: 0 auto;
    background-color: white;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(-50%);
    vertical-align: middle;
    white-space: nowrap;
  }

  &__value {
    width: 100%;
    margin-top: -15px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    &--top {
      font-size: 12px;
      font-weight: bold;
      vertical-align: text-top;
    }
  }
}
</style>

<template>
  <div>
    <v-text-field
      v-model="contact.name"
      label="Jméno a Příjmení"
      class="mb-2"
      outlined
      dense
      hide-details="auto"
      :error-messages="contactErrors.name"
    />
    <div class="text-right">
      <v-alert
        v-if="!isGenericContact && specificContactBonus > 0"
        color="green white--text px-3 py-1 mb-2 d-inline-block caption font-weight-bold"
      >
        <span class="text-uppercase">Bonus</span>
        {{ (specificContactBonus / 100).toFixed(2) }} Kč
      </v-alert>
    </div>
    <v-text-field
      ref="title"
      v-model="contact.title"
      label="Pozice"
      class="mb-2"
      outlined
      dense
      hide-details="auto"
      :error-messages="contactErrors.title"
    />
    <v-text-field
      ref="email"
      v-model="contact.email"
      :rules="[rules.required, validContactEmail]"
      label="E-mail*"
      class="mb-2"
      outlined
      dense
      hide-details="auto"
      :error-messages="contactErrors.email"
    />
    <v-text-field
      ref="link"
      v-model="contact.link"
      :rules="[rules.required]"
      label="Kde jste tyto údaje našli*"
      class="mb-0"
      outlined
      dense
      hide-details="auto"
      :error-messages="contactErrors.link"
    />
  </div>
</template>

<script>
import rules from '@/helpers/rules';
import isContactGeneric from '@/helpers/is_contact_generic';

export default {
  props: {
    index: { type: Number, default: 0 },
    contact: { type: Object, default: () => ({}) },
    contactErrors: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      rules,
    };
  },

  computed: {
    isGenericContact() {
      return isContactGeneric(this.contact, this.jobGenericContactEmailPatterns);
    },

    fullContactReward() {
      return this.$store.state.job.contactRewards[this.index] || 0;
    },

    specificContactBonus() {
      return this.fullContactReward * this.$store.state.job.genericContactPenaltyPct;
    },

    jobGenericContactEmailPatterns() {
      return this.$store.state.job.genericContactEmailPatterns;
    },
  },

  methods: {
    validate() {
      const emailOk = this.$refs.email.validate(true);
      const linkOk = this.$refs.link.validate(true);

      return emailOk && linkOk;
    },

    validContactEmail(email) {
      if (!email) return true;
      if (email.indexOf('@') >= 0) return true;

      return 'Neplatný e-mail.';
    },
  },
};
</script>

function isContactGeneric(contact, genericContactEmailPatterns) {
  if (contact.name.trim() === '') return true;
  if (contact.email.trim() === '') return true;
  if (contact.email.indexOf('@') < 0) return true;

  const email = contact.email.toLowerCase().trim();
  for (const pattern of genericContactEmailPatterns) {
    const regexp = new RegExp(pattern, 'i');
    if (email.match(regexp)) return true;
  }

  return false;
}

export default isContactGeneric;

<template>
  <v-main class="pa-0">
    <v-container class="px-10 py-5">
      <NameHeader />

      <div class="d-flex justify-space-between align-center mb-4 text--secondary">
        <div>
          <div class="title">
            <v-icon class="text--secondary" style="vertical-align: text-bottom" @click="home">mdi-arrow-left</v-icon>
            Můj účet
          </div>
        </div>
      </div>

      <div class="d-flex">
        <InfoBox class="infoBoxFixedWidth">
          <template #title>K Vyplacení</template>

          <div class="text-center">
            <div class="infoBoxEarned__jobs green--text display-2 font-weight-bold mb-3">
              {{ payout / 100 }}
              <span class="body-2 font-weight-bold">Kč</span>
            </div>

            <v-btn
              color="primary"
              href="https://kamino.slideslive.com/payouts"
              target="_blank"
              rel="noopener noreferrer"
              >Poslat peníze na účet</v-btn
            >
          </div>
        </InfoBox>

        <InfoBox class="ml-6 infoBoxFixedWidth">
          <template #title>Ověřuje se</template>

          <div class="text-center">
            <div class="infoBoxEarned__jobs display-2 font-weight-bold mb-3">
              {{ jobsVerifying }}
              <span class="text-uppercase body-2 font-weight-bold">úkolů</span>
            </div>
            <div class="text-uppercase text--secondary font-weight-bold" style="line-height: 36px">
              V hodnotě {{ jobsVerifyingEarned / 100 }} Kč
            </div>
          </div>
        </InfoBox>

        <InfoBox class="ml-6 flex-grow-1">
          <template #title>Úroveň</template>

          <div class="text-uppercase subtitle-1 font-weight-bold">Úroveň {{ level }}</div>
          <div class="text-uppercase text--secondary subtitle-1 font-weight-bold mb-2">
            Do další úrovně zbývá {{ jobsToNextLevel - jobsDoneThisLevel }} úkolů
          </div>
          <LevelBar :min-width="280" />
        </InfoBox>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import BrowserAddonController from '@/browser_addon_controller';
import InfoBox from '@/components/InfoBox.vue';
import NameHeader from '@/components/NameHeader.vue';
import LevelBar from '@/components/LevelBar.vue';

export default {
  components: {
    InfoBox,
    NameHeader,
    LevelBar,
  },

  computed: {
    ...mapState({
      level: (state) => state.person.level,
      jobsDoneThisLevel: (state) => state.person.jobsDoneThisLevel,
      jobsToNextLevel: (state) => state.person.jobsToNextLevel,
    }),

    ...mapGetters({
      payout: 'payout',
      jobsVerifying: 'jobsVerifying',
      jobsVerifyingEarned: 'jobsVerifyingEarned',
    }),
  },

  mounted() {
    BrowserAddonController.instance().makeAddonWindowWide();
  },

  methods: {
    home() {
      this.$store.commit('goTo', '/home');
    },
  },
};
</script>

<style lang="postcss">
.jobName {
  overflow: hidden;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shrink {
  width: 1px;
  white-space: nowrap;
}

.infoBoxEarned {
  &__jobs {
    position: relative;

    span {
      position: relative;
      top: 7px;
      vertical-align: text-top;
    }
  }
}

.infoBoxFixedWidth {
  width: 260px;
}
</style>

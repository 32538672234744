class BrowserAddonController {
  static instance() {
    if (!global._browserAddonController) {
      global._browserAddonController = new BrowserAddonController();
    }

    return global._browserAddonController;
  }

  constructor() {
    this.callbacks = new Map();
    this.fetchStatePromiseCallbacks = [];
    this.versionPromiseCallbacks = [];

    window.addEventListener(
      'message',
      (event) => {
        if (typeof event.data !== 'object') {
          return;
        }

        if (
          !event.data ||
          !event.data.method ||
          !event.data.method.startsWith ||
          !event.data.method.startsWith('kamino-frontend:')
        ) {
          return;
        }

        const method = event.data.method.split(':')[1];
        const params = event.data.params || {};

        this._callCallbacks(method, params);
      },
      false,
    );

    this.on('addonVersion', this._processVersionResponse.bind(this));
    this.on('fetchState', this._processFetchStateResponse.bind(this));
  }

  fetchState() {
    return new Promise((resolve) => {
      if (window === window.parent) {
        resolve({ state: null });
        return;
      }

      const callback = ({ stateId, state }) => {
        resolve({ stateId, state });
      };

      this.fetchStatePromiseCallbacks.push(callback);
      this._postMessage('fetchState');
    });
  }

  pushState({ stateId, state }) {
    this._postMessage('pushState', { stateId, state });
  }

  on(method, callback) {
    if (!this.callbacks.has(method)) {
      this.callbacks.set(method, new Set());
    }

    this.callbacks.get(method).add(callback);
  }

  version() {
    return new Promise((resolve) => {
      if (window === window.parent) {
        resolve({ name: 'none', version: 'none' });
        return;
      }

      const versionCallback = ({ name, version }) => {
        resolve({ name, version });
      };

      this.versionPromiseCallbacks.push(versionCallback);
      this._postMessage('version');
    });
  }

  show() {
    this._postMessage('show');
  }

  hide() {
    this._postMessage('hide');
  }

  makeAddonWindowNarrow() {
    this._postMessage('resize', { width: 'narrow' });
  }

  makeAddonWindowWide() {
    this._postMessage('resize', { width: 'wide' });
  }

  navigate(url) {
    this._postMessage('navigate', { url });
  }

  enableSelectionTool(type) {
    this._postMessage('enableSelectionTool', { type });
  }

  disableSelectionTool() {
    this._postMessage('disableSelectionTool');
  }

  _postMessage(method, params) {
    if (window === window.parent) {
      return;
    }

    window.parent.postMessage(
      {
        method: `kamino-addon:${method}`,
        params,
      },
      '*',
    );
  }

  _callCallbacks(method, params) {
    if (!this.callbacks.has(method)) {
      return;
    }

    for (const callback of this.callbacks.get(method)) {
      callback(params);
    }
  }

  _processFetchStateResponse({ state }) {
    for (const callback of this.fetchStatePromiseCallbacks) {
      callback({ state });
    }

    this.fetchStatePromiseCallbacks = [];
  }

  _processVersionResponse({ name, version }) {
    for (const versionCallback of this.versionPromiseCallbacks) {
      versionCallback({ name, version });
    }

    this.versionPromiseCallbacks = [];
  }
}

export default BrowserAddonController;

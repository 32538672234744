import * as chrono from 'chrono-node';

export function requiredRule(value) {
  return !!value || (value && value !== '') || (value && (!value.trim || value.trim() !== '')) || 'Povinné pole.';
}

export function emailRule(value) {
  return !value || value.indexOf('@') >= 0 || 'Neplatný e-mail.';
}

export function dateRule(value) {
  return !value || !!value.match(/^\s*[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}\s*$/) || 'Neplatný datum.';
}

export function futureDateRule(value) {
  if (!value) return true;

  const p = chrono.en.GB.parse(value, new Date(), { forwardDate: true });
  if (p.length === 0) return 'Neplatný datum.';

  const date = p[0].start.date();
  if (date > new Date()) return true;

  return 'Datum musí být v budoucnosti.';
}

export default {
  required: (v) => requiredRule(v),
  email: (v) => emailRule(v),
  date: (v) => dateRule(v),
  futureDateRule: (v) => futureDateRule(v),
};

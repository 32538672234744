<template>
  <div v-if="!!error" class="red text-center white--text py-1 px-6">{{ error }}</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      error: (state) => state.globalError,
    }),
  },
};
</script>

<template>
  <Job ref="job" v-slot="props" job-type="details" :reward="detailsReward" send-text="Ověřit údaje v databázi">
    <div class="px-6 py-4 d-flex flex-column g-2">
      <ConferenceLinkBox
        v-if="!props.sending"
        :reporting="props.reporting"
        :reported="props.reported"
        :skipping="props.skipping"
        :skipped="props.skipped"
        :report-reward="reportReward"
        @not-conference="props.sendNotConference"
        @already-happened="props.sendAlreadyHappened"
        @report="props.sendCustomReport"
      />

      <ConferenceDetailsBox v-if="props.sending" :verifying="props.sending" :contacts-already-exists="props.sent" />

      <template v-if="!props.anyActionInProgress">
        <div class="text-body-2">
          <div class="font-weight-bold">Vyplňujte pouze weby konferencí</div>
        </div>

        <ConferenceDetailsInputs :reporting="props.reporting" :disabled-buttons="props.sending" />
      </template>
    </div>
  </Job>
</template>

<script>
import { mapGetters } from 'vuex';

import Job from '@/components/Job.vue';
import ConferenceLinkBox from '@/components/ConferenceLinkBox.vue';
import ConferenceDetailsBox from '@/components/ConferenceDetailsBox.vue';
import ConferenceDetailsInputs from '@/components/ConferenceDetailsInputs.vue';

export default {
  components: {
    Job,
    ConferenceDetailsBox,
    ConferenceLinkBox,
    ConferenceDetailsInputs,
  },

  computed: {
    ...mapGetters({
      reportReward: 'jobReportReward',
      detailsReward: 'jobDetailsReward',
    }),
  },

  methods: {},
};
</script>
